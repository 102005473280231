// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-assign-force-js": () => import("../src/pages/assign-force.js" /* webpackChunkName: "component---src-pages-assign-force-js" */),
  "component---src-pages-dashboard-js": () => import("../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-edit-profile-js": () => import("../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-edit-user-roles-js": () => import("../src/pages/edit-user-roles.js" /* webpackChunkName: "component---src-pages-edit-user-roles-js" */),
  "component---src-pages-filters-js": () => import("../src/pages/filters.js" /* webpackChunkName: "component---src-pages-filters-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-list-js": () => import("../src/pages/invitation-list.js" /* webpackChunkName: "component---src-pages-invitation-list-js" */),
  "component---src-pages-invitation-js": () => import("../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-password-reset-js": () => import("../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-password-js": () => import("../src/pages/password.js" /* webpackChunkName: "component---src-pages-password-js" */),
  "component---src-pages-permissions-js": () => import("../src/pages/permissions.js" /* webpackChunkName: "component---src-pages-permissions-js" */),
  "component---src-pages-profile-js": () => import("../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reassign-receptor-js": () => import("../src/pages/reassign-receptor.js" /* webpackChunkName: "component---src-pages-reassign-receptor-js" */),
  "component---src-pages-receptor-details-js": () => import("../src/pages/receptor-details.js" /* webpackChunkName: "component---src-pages-receptor-details-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-remissions-js": () => import("../src/pages/remissions.js" /* webpackChunkName: "component---src-pages-remissions-js" */),
  "component---src-pages-remit-session-js": () => import("../src/pages/remit-session.js" /* webpackChunkName: "component---src-pages-remit-session-js" */),
  "component---src-pages-reports-js": () => import("../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-session-js": () => import("../src/pages/session.js" /* webpackChunkName: "component---src-pages-session-js" */),
  "component---src-pages-users-js": () => import("../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

