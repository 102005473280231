// Retorna la URL completa hacia el recurso del API
// uri string no debe incluir el slash del inicio
// si el entorno es testing agrega el sufijo automáticamente
const API = uri => {
  let url = `${process.env.API_URL}/${uri}`

  if (process.env.ENV === 'testing') {
    url += '_testing'
  }

  return url + '/'
}

export default API
