import isAfter from 'date-fns/isAfter'
import fromUnixTime from 'date-fns/fromUnixTime'

const keyAuth = 'ldv-jwt'

// indica si el objeto window está presente
export const isBrowser = () => typeof window !== 'undefined'

/**
 * @deprecated
 * Usa checkJwt en su lugar.
 * Retorna true si token JWT está en local storage
 */
export const isLoggedIn = () => {
  if (isBrowser() && window.localStorage.getItem('ldv-jwt')) {
    return new Date(1000 * jwt().exp) > new Date()
  }

  return false
}

/**
 * Actualiza el jwt en localStorage
 * @param {string} jwtString - Debe incluir el prefijo "Bearer "
 */
export const setJwtString = (jwtString) => {
  window.localStorage.setItem(keyAuth, jwtString)
}

// retorna el token JWT en formato string para usar en los encabezados
// retorna null si no existe el token en local storage
export const getJwtString = () => {
  if (isBrowser()) {
    const value = window.localStorage.getItem(keyAuth)
    if (!value || ['undefined', 'null'].includes(value)) {
      return null
    }
    return value
  }
  return null
}

/**
 * Retorna false si ldv-jwt en localStorage es inválido
 */
export const jwtCheck = () => {
  const jwt = getJwtString()
  if (!jwt) { return false }

  // obtiene el payload de jwt
  const payload = JSON.parse(window.atob(jwt.split('.')[1]))

  // expiró?
  if (isAfter(Date.now(), fromUnixTime(payload.exp))) {
    cleanStorage()
    return false
  }

  return true
}

/**
 * Retorna la cantidad de segundos que faltan para que jwt expire
 */
export const getJwtExp = () => {
  const jwt = getJwtString()
  if (!jwt) { return 0 }

  // obtiene el payload de jwt
  const payload = JSON.parse(window.atob(jwt.split('.')[1]))

  // diferencia en milisegundos
  const diff = fromUnixTime(payload.exp) - Date.now()

  return Math.round(diff / 1000)
}

/**
 * @deprecated
 * usa cleanStorage en su lugar.
 * Elimina el token JWT de local storage
 */
export const logout = () => {
  if (isBrowser()) {
    window.localStorage.removeItem('ldv-jwt')
    window.localStorage.removeItem('ldv-background')
    window.localStorage.removeItem('ldv-loginTime')
    // window.location.href = '/'
  }
}

/**
 * Elimina de localStorage los elementos que inicien con "ldv-"
 */
export const cleanStorage = () => {
  Object.keys(window.localStorage).forEach(key => {
    if (/^ldv-/.test(key)) {
      window.localStorage.removeItem(key)
    }
  })
}

// retorna la información (payload) del token JWT en un objeto
export const jwt = () => {
  if (isBrowser()) {
    const token = getJwtString()
    if (token) {
      return JSON.parse(window.atob(token.split('.')[1]))
    }
  }

  return {
    payload: {
      user_id: 0,
      permissions: [],
      roles: []
    }
  }
}

//
/**
 * Retorna true si el permiso dado está en JWT
 * @param {number} permissionID
 */
export function hasPermission (permissionID) {
  return jwt().payload.permissions.includes(permissionID)
}

/**
 * Retorna true si al menos uno los IDs recibidos está en JWT.
 * @param {number[]} IDs - IDs de permisos
 * @returns {boolean}
 */
export function permissionsHasOneOf (IDs) {
  for (let i = 0; i < IDs.length; i++) {
    if (jwt().payload.permissions.includes(IDs[i])) {
      return true
    }
  }

  return false
}

/**
 * Retorna true si ID del rol dado está en el token JWT
 * @deprecated use hasPermission() en su lugar
 * @param {number} roleID
 */
export function hasRole (roleID) {
  return jwt().payload.roles.includes(roleID)
}

/**
 * Retorna true si al menos uno los IDs recibidos está en JWT.
 * @param {number[]} IDs - IDs de roles
 * @returns {boolean}
 */
export function rolesHasOneOf (IDs) {
  for (let i = 0; i < IDs.length; i++) {
    if (jwt().payload.roles.includes(IDs[i])) {
      return true
    }
  }

  return false
}

export default jwt
